<template>
  <div class="annex" v-loading="loading">
    <div v-if="introduce == 'project'" class="project-jincome-top">
      <div>
        项目名称： <span class="project-jincome-fw">{{ projectInformation.projectName }}</span>
      </div>
      <div v-if="projectInformation.requireAccomplishDate">
        项目截止时间：<span class="project-jincome-fw">{{
          projectInformation.requireAccomplishDate | dateFormat
        }}</span>
        {{ isExceed ? '超出' : '剩余' }}：<span
          :class="
            isExceed
              ? 'deadline-color-red project-jincome-fw'
              : 'deadline-color-green project-jincome-fw'
          "
          >{{ deadline }}</span
        >天
      </div>
    </div>
    <div class="footer-statistics">
      <span class="footer-statistics-total">合计：</span>
      <span
        >实际合同金额： <span style="color: #00ff00">{{ agreement | thousands }}</span></span
      >
      <span class="footer-statistics-ccenter"
        >已开票金额： <span style="color: #4846a5"> {{ Invoiced | thousands }}</span></span
      >
      <span class="footer-statistics-ccenter"
        >已收款金额： <span style="color: #0c7605"> {{ Received | thousands }}</span></span
      >
      <span class="footer-statistics-ccenter"
        >已开票未收款：
        <span style="color: #ef0808"> {{ InvoicedUncollected | thousands }}</span></span
      >
      <span class="footer-statistics-ccenter"
        >未开票已收款：
        <span style="color: #e5aa25"> {{ notInvoicedReceived | thousands }}</span></span
      >
      <span class="footer-statistics-ccenter"
        >未开票金额： <span style="color: #ef2969"> {{ notInvoicedMoney | thousands }}</span></span
      >
      <span class="footer-statistics-ccenter"
        >总税费： <span style="color: #81d2e8"> {{ taxesFee | thousands }}</span></span
      >
    </div>
    <el-row style="margin-bottom: 10px; color: #303133"
      >金额单位：<span style="color: red"> 元</span>；
      <span class="leftTable"
        >合同阶段
        <div class="colorTable tableOneClassName"></div
      ></span>
      <span class="leftTable"
        >开票明细
        <div class="colorTable tableTwoClassName"></div
      ></span>
      <span class="leftTable"
        >收款明细
        <div class="colorTable tableThreeClassName"></div
      ></span>
      <span class="leftTable"
        >冲红数据
        <div class="colorTable tableRowClassName"></div
      ></span>
    </el-row>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      row-key="id"
      default-expand-all
      @cell-click="handleCellClick"
      :header-row-class-name="tableOneClassName"
    >
      <el-table-column type="expand" width="30">
        <template slot-scope="props">
          <el-table
            ref="tree"
            :data="props.row.agreementStageTaskList"
            tooltip-effect="dark"
            size="mini"
            default-expand-all
            row-key="id"
            @cell-click="handleCellClick"
            :row-class-name="tableRowClassName"
            :header-row-class-name="tableTwoClassName"
            style="width: 98%; margin-bottom: 40px; margin-top: 0px; margin-left: 2%"
          >
            <el-table-column type="expand" width="30">
              <template slot-scope="propsPayment">
                <el-table
                  ref="tree"
                  :data="propsPayment.row.agreementStagePaymentList"
                  tooltip-effect="dark"
                  size="mini"
                  default-expand-all
                  @cell-click="handleCellClick"
                  :header-row-class-name="tableThreeClassName"
                  style="width: 94.7%; margin-bottom: 0px; margin-top: 0px; margin-left: 5.3%"
                >
                  <el-table-column align="center" type="index" label="序号" width="50">
                    <template slot-scope="scope">
                      {{ props.$index + 1 }}.{{ propsPayment.$index + 1 }}. {{ scope.$index + 1 }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="proceedsDate"
                    align="center"
                    label="收款时间"
                    min-width="110"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.proceedsDate | dateFormat }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="proceedsAmount"
                    align="center"
                    label="收款金额"
                    min-width="110"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.proceedsAmount | thousands }}
                    </template>
                  </el-table-column>

                  <el-table-column prop="fileList" align="center" label="收款附件" min-width="160">
                    <template slot-scope="scope">
                      <template v-for="(n, i) in scope.row.fileList">
                        <div :key="i" style="margin-top: 5px">
                          <a
                            class="fj-text"
                            target="_blank"
                            style="text-decoration: revert; color: #409eff; padding-right: 8px"
                            :href="`${filepath}${n.filePath}`"
                          >
                            {{ n.fileName }}
                          </a>
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column prop="remark" min-width="120" align="center" label="备注">
                    <template slot="header" slot-scope="scope"
                      >备注
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="点击备注内容修改"
                        placement="top"
                      >
                        <i class="el-icon-edit"></i>
                      </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                      <span style="cursor: pointer">{{ scope.row.remark }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="操作"
                    width="120"
                    v-if="options.ProjectDisable == 5 && props.row.proceedsStatus == 10"
                  >
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        @click="editPaymentFn(scope.row, propsPayment.row)"
                        size="mini"
                        class="Edit_Bgc operate"
                        >编辑</el-button
                      >
                      <el-button
                        type="text"
                        v-if="isPermissionEdit ||  (propsPayment.row.proceedsAmount != propsPayment.row.invoiceAmount)"
                        @click="delPaymentFn(scope.row, propsPayment.row)"
                        class="emove_Bgc operate"
                        size="mini"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column align="center" label="序号" width="50">
              <template slot-scope="scope">
                {{ props.$index + 1 }}.{{ scope.$index + 1 }}
              </template>
            </el-table-column>

            <el-table-column prop="invoiceStatus" align="center" width="100" label="开票状态">
              <template slot-scope="scope">
                {{ scope.row.invoiceStatus | dict(invoiceStatus) }}
              </template>
            </el-table-column>
            <el-table-column prop="invoiceDate" align="center" width="100" label="开票时间">
              <template slot-scope="scope">
                {{ scope.row.invoiceDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="invoiceAmount" align="center" width="100" label="开票金额">
              <template slot-scope="scope">
                {{ scope.row.invoiceAmount | thousands }}
              </template>
            </el-table-column>
            <el-table-column prop="proceedsDate" align="center" label="收款时间" width="100">
              <template slot-scope="scope">
                {{ scope.row.proceedsDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="proceedsAmount" align="center" label="收款金额" width="100">
              <template slot-scope="scope">
                {{ scope.row.proceedsAmount | thousands }}
              </template>
            </el-table-column>

            <el-table-column prop="proceedsStatus" align="center" label="收款状态" width="80">
              <template slot-scope="scope">
                {{ scope.row.proceedsStatus | dict(paymentStatus) }}
              </template>
            </el-table-column>

            <el-table-column
              prop="taxesFee"
              align="center"
              :label="
                originalForm.agreementInformation &&
                originalForm.agreementInformation.agreementType == 'GENERAL'
                  ? '分摊税费'
                  : '总税费'
              "
              width="110"
            >
              <template slot-scope="scope">
                {{ scope.row.taxesFee | thousands }}
              </template>
            </el-table-column>
            <el-table-column prop="" align="center" label="开票附件" width="160">
              <template slot-scope="scope">
                <template v-for="(n, i) in scope.row.fileList">
                  <div :key="i" style="margin-top: 5px">
                    <a
                      class="fj-text"
                      target="_blank"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="" align="center" label="收款总附件" width="160">
              <template slot-scope="scope">
                <template v-for="(n, i) in scope.row.allFileList">
                  <div :key="i" style="margin-top: 5px">
                    <a
                      class="fj-text"
                      target="_blank"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="remark" align="center" label="备注">
              <template slot="header" slot-scope="scope"
                >备注
                <el-tooltip class="item" effect="dark" content="点击备注内容修改" placement="top">
                  <i style="cursor: pointer" class="el-icon-edit"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <span style="cursor: pointer">{{ scope.row.remark }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="165"
              v-if="(options.ProjectDisable == 5 && props.row.proceedsStatus == 10) || isPermissionEdit"
            >
              <template slot-scope="scope">
                <template
                  v-if="
                    (options.ProjectDisable == 5 &&
                      scope.row.proceedsAmount == 0 &&
                      scope.row.invoiceAmount == 0) ||
                    (options.ProjectDisable == 5 &&
                      scope.row.proceedsAmount != scope.row.invoiceAmount) || isPermissionEdit
                  "
                >
                  <el-button
                    type="text"
                    v-if="scope.row.id && scope.row.invoiceAmount >= 0"
                    class="proceeds_Bgc operate"
                    @click="perfectFn(scope.row)"
                    size="mini"
                    >添加收款</el-button
                  >
                  <el-button
                    type="text"
                    v-if="isPermissionEdit || scope.row.invoiceStatus == 10"
                    @click="delFn(scope.row)"
                    class="emove_Bgc operate"
                    size="mini"
                    >删除</el-button
                  >
                </template>
                <el-button
                  type="text"
                  v-if="
                    (isPermissionEdit &&
                    scope.row.invoiceStatus == 10 &&
                    scope.row.proceedsStatus == 20) || isPermissionEdit
                  "
                  class="Edit_Bgc operate"
                  @click="editStaghe(scope.row)"
                  size="small"
                >
                  编辑
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>

      <el-table-column prop="stageName" align="center" label="阶段" width="134"> </el-table-column>
      <el-table-column prop="paymentStandard" min-width="230" align="center" label="阶段内容">
      </el-table-column>
      <el-table-column
        prop="paymentAmount"
        :show-overflow-tooltip="false"
        align="center"
        label="合同阶段金额"
        width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.paymentAmount | thousands }}
        </template>
      </el-table-column>
      <el-table-column prop="invoiceAmount" align="center" label="已开票金额" width="110">
        <template slot-scope="scope">
          {{ scope.row.invoiceAmount | thousands }}
        </template>
      </el-table-column>
      <el-table-column prop="proceedsAmount" align="center" label="已收款金额" width="110">
        <template slot-scope="scope">
          {{ scope.row.proceedsAmount | thousands }}
        </template>
      </el-table-column>

      <el-table-column prop="proceedsDate" align="center" label="约定收款时间" width="110">
        <template slot-scope="scope">
          {{ scope.row.proceedsDate | dateFormat }}
        </template>
      </el-table-column>
      <el-table-column prop="actualProceedsDate" label="实际收款时间" align="center" width="110">
        <template slot-scope="scope">
          {{ scope.row.actualProceedsDate | dateFormat }}
        </template>
      </el-table-column>
      <el-table-column prop="taxesFee" align="center" label="总税费" width="110">
        <template slot-scope="scope">
          {{ scope.row.taxesFee | thousands }}
        </template>
      </el-table-column>
      <el-table-column prop="proceedsStatus" align="center" label="收款状态" width="80">
        <template slot-scope="scope">
          {{ scope.row.proceedsStatus | dict(paymentStatus) }}
        </template>
      </el-table-column>
      <el-table-column prop="proceedsRemark" width="200" label="备注">
        <template slot="header" slot-scope="scope"
          >备注
          <el-tooltip class="item" effect="dark" content="点击备注内容修改" placement="top">
            <i class="el-icon-edit"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <span style="cursor: pointer">{{ scope.row.remark }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="200" v-if="options.ProjectDisable == 5">
        <template slot-scope="scope">
          <template v-if="scope.row.proceedsStatus == 10">
            <el-button
              @click="addFn(scope.row)"
              type="text"
              class="addProceeds_Bgc operate"
              size="mini"
              >增加收款</el-button
            >
          </template>
          <el-button
            type="text"
            class="isProceeds_Bgc operate"
            @click="handleClick(scope.row)"
            :disabled="
              !permission(['AGREEMENT_INCOME_EDIT']) ||
              scope.row.proceedsStatus != 10 ||
              options.ProjectDisable != 5
            "
            size="small"
          >
            <span v-if="scope.row.proceedsStatus == 10"> 确认收款 </span>
            <span v-else> 已收款 </span>
          </el-button>
          <el-button
            type="text"
            v-if="isPermissionEdit"
            class="Edit_Bgc operate"
            @click="editStaghe(scope.row, true)"
            size="small"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 阶段收款 -->
    <EditDialog
      :isReturn="true"
      @closeFn="cancelFn"
      :isShow.sync="isShow"
      width="37%"
      id="editDaalog"
    >
      <template v-slot:title>
        <span style="color: #409eff"> 添加收款信息</span>
      </template>
      <template v-slot:content>
        <el-form :model="form" ref="form" :rules="rules" style="max-height: 450px; overflow: auto">
          <el-form-item label="阶段:" prop="stageName" label-width="110px">
            <el-input disabled v-model="form.stageName"></el-input>
          </el-form-item>
          <el-form-item label="合同阶段金额：" prop="paymentAmount" label-width="110px">
            <el-input disabled v-model="form.paymentAmount"></el-input>
          </el-form-item>
          <el-form-item label="阶段内容：" prop="paymentStandard" label-width="110px">
            <el-input disabled v-model="form.paymentStandard" type="textarea" autosize></el-input>
          </el-form-item>
          <el-form-item label="收款金额：" prop="proceedsAmount" label-width="110px">
            <el-input
              type="number"
              class="numrule"
              @blur="blurAmount('proceedsAmount', 'form')"
              v-model="form.proceedsAmount"
            ></el-input>
          </el-form-item>
          <el-form-item label="收款时间：" prop="proceedsDate" label-width="110px">
            <el-date-picker
              value-format="timestamp"
              type="date"
              :clearable="false"
              v-model="form.proceedsDate"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="备注：" prop="remark" label-width="110px">
            <el-input v-model.trim="form.remark" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="附件" label-width="110px">
            <div>
              <Upload
                style="font-size: 34px; color: #409eff; margin-left: 91%"
                v-if="disabled"
                :type="type"
                :fields="fields"
                @upload="upload"
              ></Upload>
            </div>
            <div style="height: 110px; overflow: auto; width: 430px">
              <template v-for="(n, i) in form.fileList">
                <div :key="i" style="margin-top: 5px" class="fj-box">
                  <a
                    class="fj-text"
                    target="_blank"
                    style="text-decoration: revert; color: #409eff"
                    :href="`${filepath}${n.filePath}`"
                  >
                    {{ n.fileName }}
                  </a>

                  <i
                    style="font-size: 34px; color: red"
                    @click="del(n, i)"
                    class="iconfont iconerror"
                  ></i>
                </div>
              </template>
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button type="primary" @click="increaseFn">确 定</el-button>
        <el-button type="info" plain @click="cancelFn">返 回</el-button>
      </template>
    </EditDialog>

    <!-- 开票期数添加收款 -->
    <EditDialog
      :isShow.sync="PaymentIsShow"
      :isReturn="true"
      @closeFn="cancelFn"
      width="37%"
      id="PaymentIsShow"
    >
      <template v-slot:title>
        <span style="color: #409eff">{{ PaymentForm.id ? '编辑收款信息' : '添加收款信息' }}</span>
      </template>
      <template v-slot:content>
        <el-form
          :model="PaymentForm"
          ref="PaymentForm"
          :rules="rules"
          style="max-height: 450px; overflow: auto"
        >
          <el-form-item label="阶段:" prop="stageName" label-width="110px">
            <el-input disabled v-model="PaymentForm.stageName"></el-input>
          </el-form-item>
          <el-form-item label="合同阶段金额：" prop="paymentAmount" label-width="110px">
            <el-input disabled v-model="PaymentForm.paymentAmount"></el-input>
          </el-form-item>
          <el-form-item label="阶段内容：" prop="paymentStandard" label-width="110px">
            <el-input
              disabled
              v-model="PaymentForm.paymentStandard"
              type="textarea"
              autosize
            ></el-input>
          </el-form-item>
          <el-form-item label="开票金额：" prop="invoiceAmount" label-width="110px">
            <el-input disabled v-model="PaymentForm.invoiceAmount"></el-input>
          </el-form-item>
          <el-form-item label="开票时间：" prop="invoiceDate" label-width="110px">
            <el-date-picker
              value-format="timestamp"
              type="date"
              disabled
              :clearable="false"
              v-model="PaymentForm.invoiceDate"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="收款金额：" prop="proceedsAmount" label-width="110px">
            <el-input
              type="number"
              class="numrule"
              @blur="blurAmount('proceedsAmount')"
              v-model="PaymentForm.proceedsAmount"
            ></el-input>
          </el-form-item>
          <el-form-item label="收款时间：" prop="proceedsDate" label-width="110px">
            <el-date-picker
              value-format="timestamp"
              type="date"
              :clearable="false"
              v-model="PaymentForm.proceedsDate"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="备注：" prop="remark" label-width="110px">
            <el-input v-model.trim="PaymentForm.remark" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="附件" label-width="110px">
            <template slot="label"> 附件 </template>
            <div>
              <Upload
                style="font-size: 34px; color: #409eff; margin-left: 91%"
                v-if="disabled"
                :type="type"
                :fields="fields"
                @upload="taskUpload"
              ></Upload>
            </div>
            <div style="height: 110px; overflow: auto; width: 430px">
              <template v-for="(n, i) in PaymentForm.fileList">
                <div :key="i" style="margin-top: 5px" class="fj-box">
                  <a
                    class="fj-text"
                    target="_blank"
                    style="text-decoration: revert; color: #409eff"
                    :href="`${filepath}${n.filePath}`"
                  >
                    {{ n.fileName }}
                  </a>

                  <i
                    style="font-size: 34px; color: red"
                    @click="taskDel(n, i)"
                    class="iconfont iconerror"
                  ></i>
                </div>
              </template>
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button type="primary" @click="paymentIncreaseFn">确 定</el-button>
        <el-button type="info" plain @click="cancelFn">返 回</el-button>
      </template>
    </EditDialog>

    <EditDialog :isReturn="true" @closeFn="cancel" :isShow.sync="submitDialog" width="30%">
      <template v-slot:title>
        <h4
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            color: #409eff;
            fontsize: 20px;
            font-weight: 600;
          "
        >
          <span>确认已完成收款</span>
        </h4>
      </template>
      <template v-slot:content>
        <el-form :model="formData" ref="formData">
          <el-form-item label="最后收款时间：" prop="actualProceedsDate" label-width="110px">
            <el-date-picker
              value-format="timestamp"
              type="date"
              :clearable="false"
              v-model="formData.actualProceedsDate"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="备注：" prop="proceedsRemark" label-width="100px">
            <el-input v-model.trim="formData.proceedsRemark" type="textarea" autosize></el-input>
          </el-form-item>
        </el-form>
        <div style="color: red; margin-left: 16%">
          * PS：请核对该阶段的合同信息
          <span style="color: #1ab119"> 开票 收款 </span> 等信息是否已补充完毕
        </div>
      </template>
      <template v-slot:footer>
        <el-button type="primary" @click="defineFn">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </template>
    </EditDialog>

    <!-- 内容展示 -->
    <EditDialog
      :isReturn="true"
      @closeFn="contentDialogVisible = false"
      :isShow.sync="contentDialogVisible"
      width="50%"
    >
      <template v-slot:title>
        <span style="color: #409eff">{{ contentTitle }}</span>
      </template>
      <template v-slot:content>
        <div class="dialogContentHeight">
          <div v-if="contentTitle != '税费内容展示'">
            <!-- contentDialogDisable -->
            <el-input
              type="textarea"
              :disabled="contentDialogDisable"
              :autosize="{ minRows: 5, maxRows: 10 }"
              v-model="contentDialog"
            ></el-input>
            <!-- {{ contentDialog }} -->
          </div>
          <el-table v-else :data="taxesFeeArr" border>
            <el-table-column prop="vatType" align="center" label="增值税类型">
              <template slot-scope="scope">
                {{ scope.row.vatType | transitionType(zzsList) }}
              </template>
            </el-table-column>
            <el-table-column prop="vatData" align="center" label="增值税类型详细">
              <template slot-scope="scope">
                {{ scope.row.vatData | transitionData(VatDataAll) }}
              </template>
            </el-table-column>
            <el-table-column prop="vatFee" align="center" label="增值税" width="80">
              <template slot-scope="scope">
                {{ scope.row.vatFee | thousands }}
              </template>
            </el-table-column>
            <el-table-column prop="additionalTaxFee" align="center" label="附加税" width="80">
              <template slot-scope="scope">
                {{ scope.row.additionalTaxFee | thousands }}
              </template>
            </el-table-column>
            <el-table-column prop="stampTaxFee" align="center" label="印花税" width="80">
              <template slot-scope="scope">
                {{ scope.row.stampTaxFee | thousands }}
              </template>
            </el-table-column>
            <el-table-column prop="taxesFee" align="center" label="总税费" width="80">
              <template slot-scope="scope">
                {{ scope.row.taxesFee | thousands }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <template v-slot:footer>
        <el-button type="primary" plain @click="contentDialogVisible = false">返 回</el-button>
      </template>
    </EditDialog>

    <EditDialog
      :isReturn="true"
      @closeFn="isStageShow = false"
      :isShow.sync="isStageShow"
      width="35%"
    >
      <template v-slot:title>
        <span>{{ stageInfo.type ? '编辑阶段' : stageInfo.title }}信息</span>
      </template>
      <template v-slot:content>
        <el-form :model="stageInfo" style="height: 500px; overflow-y: auto; overflow-x: hidden">
          <template v-if="stageInfo.type">
            <el-form-item label="已开票金额" label-width="110px">
              <el-input
                v-model="stageInfo.invoiceAmount"
                type="number"
                class="numrule"
                @blur="onBlurAmount('invoiceAmount')"
              ></el-input>
            </el-form-item>
            <el-form-item label="已收款金额" label-width="110px">
              <el-input
                v-model="stageInfo.proceedsAmount"
                type="number"
                class="numrule"
                @blur="onBlurAmount('proceedsAmount')"
              ></el-input>
            </el-form-item>
            <el-form-item label="实际收款时间" label-width="110px">
              <el-date-picker
                value-format="timestamp"
                type="date"
                :clearable="false"
                v-model="stageInfo.actualProceedsDate"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="收款状态" label-width="110px">
              <Dictionary
                v-model="stageInfo.proceedsStatus"
                code="AGREEMENT_PAYMENT_STATUS"
                placeholder="请选择合同收款状态"
              />
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="开票状态" label-width="110px">
              <Dictionary
                v-model="stageInfo.invoiceStatus"
                code="AGREEMENT_TICKET"
                placeholder="请选择开开票状态"
              />
            </el-form-item>
            <el-form-item label="开票时间" label-width="110px">
              <el-date-picker
                value-format="timestamp"
                type="date"
                :clearable="false"
                v-model="stageInfo.invoiceDate"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="已开票金额" label-width="110px">
              <el-input
                v-model="stageInfo.invoiceAmount"
                type="number"
                class="numrule"
                @blur="onBlurAmount('invoiceAmount')"
              ></el-input>
            </el-form-item>
            <el-form-item label="收款时间" label-width="110px">
              <el-date-picker
                value-format="timestamp"
                type="date"
                :clearable="false"
                v-model="stageInfo.proceedsDate"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="收款金额" label-width="110px">
              <el-input
                v-model="stageInfo.proceedsAmount"
                type="number"
                class="numrule"
                @blur="onBlurAmount('proceedsAmount')"
              ></el-input>
            </el-form-item>
            <el-form-item label="收款状态" label-width="110px">
              <Dictionary
                v-model="stageInfo.proceedsStatus"
                code="AGREEMENT_PAYMENT_STATUS"
                placeholder="请选择合同收款状态"
              />
            </el-form-item>
            <el-form-item label="备注" label-width="110px">
              <el-input v-model="stageInfo.remark" type="textarea" autosize></el-input>
            </el-form-item>
            <el-form-item label="开票附件" label-width="110px">
              <div>
                <Upload
                  style="font-size: 34px; color: #409eff; margin-left: 91%"
                  v-if="disabled"
                  :type="type"
                  :fields="fields"
                  @upload="invoiceUpload"
                ></Upload>
              </div>
              <div style="height: 110px; overflow: auto; width: 430px">
                <template v-for="(n, i) in stageInfo.fileList">
                  <div :key="i" style="margin-top: 5px" class="fj-box">
                    <a
                      class="fj-text"
                      target="_blank"
                      style="text-decoration: revert; color: #409eff"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>

                    <i
                      style="font-size: 34px; color: red"
                      @click="invoiceDel(n, i)"
                      class="iconfont iconerror"
                    ></i>
                  </div>
                </template>
              </div>
            </el-form-item>
            <el-form-item label="收款附件" label-width="110px" v-if="stageInfo.isAllFileList">
              <div>
                <Upload
                  style="font-size: 34px; color: #409eff; margin-left: 91%"
                  v-if="disabled"
                  :type="type"
                  :fields="fields"
                  @upload="proceedsUpload"
                ></Upload>
              </div>
              <div style="height: 110px; overflow: auto; width: 430px">
                <template v-for="(n, i) in stageInfo.allFileList">
                  <div :key="i" style="margin-top: 5px" class="fj-box">
                    <a
                      class="fj-text"
                      target="_blank"
                      style="text-decoration: revert; color: #409eff"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>

                    <i
                      style="font-size: 34px; color: red"
                      @click="proceedsDel(n, i)"
                      class="iconfont iconerror"
                    ></i>
                  </div>
                </template>
              </div>
            </el-form-item>
          </template>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button type="primary" @click="onEditStageFn">确 定</el-button>
        <el-button @click="isStageShow = false">取 消</el-button>
      </template>
    </EditDialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    UploadList: () => import('@/components/upload/List.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    introduce: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      isShow: false,
      form: {
        //开票明细
        stageName: null,
        paymentStandard: null,
        paymentAmount: null,
        proceedsAmount: null,
        proceedsDate: Date.now(),
        agreementStagePlanId: null,
        fileList: [],
      },
      PaymentForm: {
        //付款明细
        proceedsDate: Date.now(),
        proceedsAmount: null,
        agreementStagePlanId: null, //合同阶段id
        agreementStageTaskId: null, //开票明细期数id
        fileList: [],
        fileDeleteList: [],
        // 开票期数内容
        invoiceAmount: null,
        readonlyProceedsAmount: null,
        invoiceDate: Date.now(),
        // 阶段内容
        stageName: null,
        paymentStandard: null,
        paymentAmount: null,
      },
      PaymentIsShow: false, //开票明细添加收款
      formData: {
        //确认收款表单
        proceedsRemark: '',
        agreementStagePlanId: '',
        actualProceedsDate: Date.now(),
      },
      rules: {
        proceedsAmount: [{ required: true, message: '请输入收款金额', trigger: 'blur' }],
      },
      submitDialog: false,

      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'filType',
      },
      type: 'HTFKtype',
      tableData: [],
      disabled: false, //
      paymentStatus: [],
      invoiceStatus: [],
      zzsList: [],
      VatDataAll: [],
      // expends: [],
      // TaskExpends: [],
      contentDialog: '', //阶段内容/备注/阶段
      contentDialogDisable: false, // 禁止编辑
      taxesFeeArr: [],
      contentTitle: '',
      contentDialogVisible: false,
      isExceed: false,
      isStageShow: false,
      stageInfo: {},
      remarkValue: '',
    }
  },
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
      originalForm: state => state.project.originalForm,
    }),
    agreement() {
      //实际合同金额 取合同基本信息实际合同金额
      return this.originalForm?.agreementInformation?.actualContractAmount || 0
    },
    // 合同阶段金额
    stageAgreement() {
      let num = 0
      this.tableData.forEach(v => {
        num += Number(v.paymentAmount)
      })
      return num
    },
    Invoiced() {
      //已开票金额
      let num = 0
      this.tableData.forEach(v => {
        num += Number(v.invoiceAmount)
      })
      return num
    },
    Received() {
      //已收款金额
      let num = 0
      this.tableData.forEach(v => {
        num += Number(v.proceedsAmount)
      })
      return num
    },
    InvoicedUncollected() {
      //已开票未收款 = 已开票金额 - 已收款金额
      let num = 0
      num = this.Invoiced - this.Received
      return num
    },
    notInvoicedReceived() {
      //未开票已收款
      let num = 0
      this.tableData.forEach(v => {
        v.agreementStageTaskList.forEach(n => {
          if (n.proceedsStatus == 20 && n.invoiceStatus == 10) {
            num += Number(n.proceedsAmount)
          }
        })
      })
      return num
    },
    notInvoicedMoney() {
      // 未开票金额 = 实际合同金额 — 已开票金额
      let num = 0
      num = this.agreement - this.Invoiced
      return num
    },
    // 总税费
    taxesFee() {
      let num = 0
      this.tableData.forEach(v => {
        v.agreementStageTaskList.forEach(n => {
          num += Number(n.taxesFee)
        })
      })
      return num
    },
    // 项目截止时间
    deadline() {
      let timestamp = this.projectInformation.requireAccomplishDate // 项目要求完结时间
      // 获取当前日期，设置为00:00:00以去掉时分秒
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0) // 将当前时间设置为00:00:00
      const currentTimestamp = currentDate.getTime() // 当前日期的时间戳（毫秒）
      // 转换为天数
      let daysDifference = (timestamp - currentTimestamp) / (1000 * 60 * 60 * 24)
      daysDifference = Math.floor(daysDifference)
      if (daysDifference > 0) {
        this.isExceed = false
      } else {
        this.isExceed = true
      }
      return daysDifference
    },
    isPermissionEdit() {
      if (this.originalForm?.agreementInformation?.isPermissionEdit) {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    this.getType()
  },
  watch: {
    originalForm: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        if (this.permission(['AGREEMENT_INCOME_EDIT'])) {
          this.disabled = true
        } else {
          this.disabled = false
        }
        if (newVal.agreementStageList) {
          this.tableData = newVal.agreementStageList.deepClone()
          this.tableData.forEach(item => {
            item.taxesFee = item.agreementStageTaskList.reduce((prev, cur, index, arr) => {
              return prev + cur.taxesFee
            }, 0)
          })
          // let Id = []
          // let Expends = []
          // this.tableData.forEach(v => {
          //   if (v.agreementStageTaskList && v.agreementStageTaskList.length > 0) {
          //     Id.push(v.id)
          //     v.agreementStageTaskList.forEach(item => {
          //       if (item.agreementStagePaymentList && item.agreementStagePaymentList.length > 0) {
          //         Expends.push(item.id)
          //       }
          //     })
          //   }
          // })
          // this.expends = Id
          // this.TaskExpends = Expends
        }
      },
    },
  },
  filters: {
    thousands(a) {
      if (a) {
        a = Number(a).toLocaleString()
        return a
      } else {
        return 0
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        if (item) {
          str = item.vatName
        }
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
  methods: {
    invoiceUpload(r) {
      r[0].type = this.type
      this.stageInfo.fileList.push(...r)
    },
    invoiceDel(row, index) {
      this.stageInfo.fileList.splice(index, 1)
      if (row.id) {
        this.stageInfo.fileDeleteList.push(row.id)
      }
    },
    proceedsUpload(r) {
      r[0].type = this.type
      this.stageInfo.allFileList.push(...r)
    },
    proceedsDel(row, index) {
      this.stageInfo.allFileList.splice(index, 1)
      if (row.id) {
        this.stageInfo.fileDeleteList.push(row.id)
      }
    },
    onEditStageFn() {
      let key = ''
      if (this.stageInfo.type) {
        key = 'editStagePlan'
      } else {
        key = 'editStageTask'
      }
      this.$api.agreement[key](this.stageInfo)
        .then(res => {
          this.$emit('getData')
          this.$message.success('编辑成功!')
          this.isStageShow = false
        })
        .catch(err => {
          this.isStageShow = false
          console.log(err)
        })
    },
    editStaghe(row, type = false) {
      const info = row.deepClone()
      this.stageInfo = info
      this.stageInfo.type = type
      this.stageInfo.isAllFileList = false
      this.stageInfo.title = '编辑明细'
      this.stageInfo.fileDeleteList = info.fileDeleteList || []
      this.stageInfo.allFileList = info.allFileList || []
      this.stageInfo.fileList = info.fileList || []
      this.isStageShow = true
    },
    onBlurAmount(e) {
      this.stageInfo[e] = Number(this.stageInfo[e]).toFixed(2)
    },

    // 冲红高亮
    tableRowClassName({ row, column, rowIndex, columnIndex }) {
      if (row.invoiceStatus == 30) {
        return ['tableRowClassName']
      }
      return []
    },

    // 阶段表头
    tableOneClassName() {
      return 'tableOneClassName'
    },
    // 开票表头
    tableTwoClassName() {
      return 'tableTwoClassName'
    },
    // 收款表头
    tableThreeClassName() {
      return 'tableThreeClassName'
    },
    // 付款明细编辑
    editPaymentFn(row, propsPayment) {
      this.PaymentForm = row.deepClone()
      this.PaymentForm.fileDeleteList = []
      // 开票期数内容
      this.PaymentForm.invoiceAmount = propsPayment.invoiceAmount
      this.PaymentForm.readonlyProceedsAmount = propsPayment.proceedsAmount
      this.PaymentForm.invoiceDate = propsPayment.invoiceDate
      //  阶段内容
      this.tableData.forEach(v => {
        if (v.id == propsPayment.agreementStagePlanId) {
          this.PaymentForm.stageName = v.stageName
          this.PaymentForm.paymentAmount = v.paymentAmount
          this.PaymentForm.paymentStandard = v.paymentStandard
        }
      })
      this.PaymentIsShow = true
    },
    // 付款明细删除
    delPaymentFn(row, propsPayment) {
      this.$confirm('是否删除该付款明细数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
      })
        .then(() => {
          this.$api.agreement
            .deleteTaskPayment(row.id)
            .then(res => {
              this.$emit('getData')
              this.$message.success('删除成功!')
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    // 打开阶段内容
    handleCellClick(row, column) {
      this.contentDialog = ''
      this.taxesFeeArr = []
      if (column.property == 'paymentStandard') {
        this.contentDialog = row.paymentStandard
        this.contentTitle = '阶段内容展示'
        this.contentDialogVisible = true
      } else if (column.property == 'proceedsRemark') {
        // this.contentDialog = row.proceedsRemark
        // this.contentTitle = '备注展示'
        // this.contentDialogVisible = true
        const h = this.$createElement

        this.$msgbox({
          title: '备注',
          message: h('textarea', {
            attrs: {
              disabled: false,
              rows: 5,
              placeholder: '请输入备注',
            },
            style: {
              width: '100%',
            },
            domProps: {
              value: row.proceedsRemark, // 使用domProps来设置初始值
            },
            on: {
              input: event => {
                // 当文本域内容变化时，更新value
                this.remarkValue = event.target.value
              },
            },
          }),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(action => {
            this.loading = true
            this.$api.agreement
              .editStagePlanPayment({
                agreementStagePlanId: row.id,
                proceedsRemark: this.remarkValue,
              })
              .then(res => {
                this.$message.success('修改成功!')
                this.$set(row, 'proceedsRemark', this.remarkValue)
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                this.loading = false
              })
          })
          .catch(action => {
            console.log(action)
            console.log('备注内容未保存')
          })
      } else if (column.property == 'stageName') {
        this.contentDialog = row.stageName
        this.contentTitle = '阶段展示'
        this.contentDialogVisible = true
      } else if (column.property == 'taxesFee') {
        this.taxesFeeArr = [row]
        this.contentTitle = '税费内容展示'
        this.contentDialogVisible = true
      } else if (column.property == 'remark') {
        // this.contentDialogDisable = false
        // this.contentDialog = row.remark
        // this.contentTitle = '备注展示'
        // this.contentDialogVisible = true
        if (column.property == 'remark') {
          const h = this.$createElement
          this.$msgbox({
            title: '备注',
            message: h('textarea', {
              attrs: {
                disabled: false,
                rows: 5,
                placeholder: '请输入备注',
              },
              style: { width: '100%' },
              domProps: {
                value: row.remark, // 使用domProps来设置初始值
              },
              on: {
                input: event => {
                  // 当文本域内容变化时，更新value
                  this.remarkValue = event.target.value
                },
              },
            }),
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          })
            .then(action => {
              this.loading = true
              if (row.hasOwnProperty('agreementStagePaymentList')) {
                // 第二层
                this.loading = true
                this.$api.agreement
                  .editStagePlanPayment({
                    agreementStageTaskReqList: [
                      {
                        id: row.id,
                        remark: this.remarkValue,
                      },
                    ],
                  })
                  .then(res => {
                    this.$set(row, 'remark', this.remarkValue)
                    this.$message.success('修改成功!')
                  })
                  .catch(err => {
                    console.log(err)
                  })
                  .finally(() => {
                    this.loading = false
                  })
              } else {
                this.loading = true
                this.$api.agreement
                  .editStagePlanPayment({
                    agreementStageTaskPaymentReqList: [
                      {
                        id: row.id,
                        remark: this.remarkValue,
                      },
                    ],
                  })
                  .then(res => {
                    this.$set(row, 'remark', this.remarkValue)
                    this.$message.success('修改成功!')
                  })
                  .catch(err => {
                    console.log(err)
                  })
                  .finally(() => {
                    this.loading = false
                  })
              }
            })
            .catch(action => {
              console.log('备注内容未保存')
            })
        }
      }
    },
    //过滤
    transitionVatType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        str = item?.vatName
      }
      return str
    },
    // 确认收款-保存
    defineFn() {
      this.$api.project
        .paymentPass(this.formData)
        .then(res => {
          this.cancel()
          this.$emit('getData')
          this.$message.success('收款成功')
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 确认收款取消
    cancel() {
      this.submitDialog = false
      this.formData = {
        proceedsRemark: '',
        agreementStagePlanId: '',
        actualProceedsDate: Date.now(),
      }
    },
    // 添加收款取消
    cancelFn() {
      this.isShow = false
      this.PaymentIsShow = false
      this.form = {
        //开票明细
        stageName: null,
        paymentStandard: null,
        paymentAmount: null,
        proceedsAmount: null,
        proceedsDate: Date.now(),
        agreementStagePlanId: null,
        fileList: [],
      }
      this.PaymentForm = {
        //付款明细
        proceedsDate: Date.now(),
        proceedsAmount: null,
        agreementStagePlanId: null, //合同阶段id
        agreementStageTaskId: null, //开票明细期数id
        fileList: [],
        fileDeleteList: [],
        // 开票期数内容
        invoiceAmount: null,
        readonlyProceedsAmount: null,
        invoiceDate: Date.now(),
        // 阶段内容
        stageName: null,
        paymentStandard: null,
        paymentAmount: null,
      }
      if (this.$refs.PaymentIsShow) {
        this.$refs.PaymentIsShow.resetFields()
      }
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
    },
    // 阶段收款添加
    increaseFn() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const item = this.tableData.find(v => v.id == this.form.agreementStagePlanId)
          if (item) {
            this.form.numberNo = item.agreementStageTaskList.length + 1
          }

          this.$api.agreement.addPayment(this.form).then(res => {
            this.$message.success('添加收款信息成功!')
            this.cancelFn()
            this.$emit('getData')
          })
        }
      })
    },
    // 期数收款添加
    paymentIncreaseFn() {
      // if (this.PaymentForm.fileList.length <= 0) {
      //   this.$message.warning('请上传收款附件')
      //   return
      // }
      this.$refs.PaymentForm.validate(valid => {
        if (valid) {
          if (this.PaymentForm.id) {
            this.$api.agreement.editTask(this.PaymentForm).then(res => {
              this.$message.success('编辑收款信息成功!')
              this.cancelFn()
              this.$emit('getData')
            })
          } else {
            this.$api.agreement.perfectionPayment(this.PaymentForm).then(res => {
              this.$message.success('添加收款信息成功!')
              this.cancelFn()
              this.$emit('getData')
            })
          }
        } else {
        }
      })
    },
    // 添加开票收款
    addFn(row) {
      const info = row.deepClone()
      if (this.isPermissionEdit) {
        this.stageInfo = {
          stageName: info.stageName,
          paymentStandard: info.paymentStandard,
          paymentAmount: info.paymentAmount,
          agreementStagePlanId: info.id,
          remark: null,
          proceedsStatus: null,
          proceedsAmount: 0,
          proceedsDate: null,
          invoiceAmount: 0,
          invoiceDate: null,
          invoiceStatus: null,
          type: false,
          fileList: [],
          allFileList: [],
          isAllFileList: true,
        }

        this.stageInfo.title = '新增明细'
        this.isStageShow = true
      } else {
        this.form.stageName = info.stageName
        this.form.paymentStandard = info.paymentStandard
        this.form.paymentAmount = info.paymentAmount
        this.form.agreementStagePlanId = info.id
        this.form.fileList = []
        this.isShow = true
      }
    },
    // 开票明细-收款删除
    delFn(row) {
      this.$confirm('是否删除该开票明细数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
      })
        .then(() => {
          this.$api.agreement
            .deleteTask(row.id)
            .then(res => {
              this.$emit('getData')
              this.$message.success('删除成功!')
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    // editFn(n, row) {
    //   if (n == 'ch') {
    //     this.$confirm('是否把这条发票数据冲红', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'success',
    //     })
    //       .then(() => {
    //         this.$api.agreement
    //           .offset(row.id)
    //           .then(res => {
    //             this.$emit('getData')
    //             this.$message.success('发票冲红成功!')
    //           })
    //           .catch(err => {
    //             console.log(err)
    //           })
    //       })
    //       .catch(() => {
    //         //用户取消
    //       })
    //     return
    //   }
    //   this.form = row.deepClone()
    //   this.tableData.forEach(v => {
    //     if (v.id == row.agreementStagePlanId) {
    //       this.form.stageName = v.stageName
    //       this.form.paymentStandard = v.paymentStandard
    //       this.form.paymentAmount = v.paymentAmount
    //     }
    //   })
    //   this.editTitle = n
    //   this.isShow = true
    // },
    // 确认收款
    handleClick(row) {
      this.formData.agreementStagePlanId = row.id
      this.submitDialog = true
    },
    upload(r) {
      r[0].type = this.type
      this.form.fileList.push(...r)
    },
    del(row, index) {
      this.form.fileList.splice(index, 1)
    },
    taskUpload(r) {
      r[0].type = this.type
      this.PaymentForm.fileList.push(...r)
    },
    taskDel(row, index) {
      this.PaymentForm.fileList.splice(index, 1)
      if (row.id) {
        this.PaymentForm.fileDeleteList.push(row.id)
      }
    },
    /** 失去焦点触发 "申请金额" 文本框 **/
    blurAmount(e, type) {
      if (type == 'form') {
        this.form[e] = Number(this.form[e]).toFixed(2)
      } else {
        this.PaymentForm[e] = Number(this.PaymentForm[e]).toFixed(2)
      }
    },
    // 期数添加收款
    perfectFn(row) {
      // 开票期数内容
      this.PaymentForm.invoiceAmount = row.invoiceAmount
      this.PaymentForm.proceedsAmount = row.invoiceAmount
      this.PaymentForm.invoiceDate = row.invoiceDate
      this.PaymentForm.agreementStageTaskId = row.id //开票明细期数id
      this.PaymentForm.agreementStagePlanId = row.agreementStagePlanId //合同阶段id
      //  阶段内容
      this.tableData.forEach(v => {
        if (v.id == row.agreementStagePlanId) {
          this.PaymentForm.stageName = v.stageName
          this.PaymentForm.paymentAmount = v.paymentAmount
          this.PaymentForm.paymentStandard = v.paymentStandard
        }
      })
      this.PaymentIsShow = true
    },
    getType() {
      this.$api.dict.listSysDictData('AGREEMENT_PAYMENT_STATUS', true).then(res => {
        this.paymentStatus = res.data
      })
      this.$api.dict.listSysDictData('AGREEMENT_TICKET', true).then(res => {
        this.invoiceStatus = res.data
      })

      this.$api.vat
        .getVatTypeList()
        .then(res => {
          this.zzsList = res?.data?.records
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.vat
        .getVatDataList({ pageNow: 1, pageSize: 99999 })
        .then(res => {
          if (res.data?.records) {
            this.VatDataAll = res.data.records
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  beforeDestroy() {},
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.annex {
  /deep/.el-card {
    //width:50%;
    .annex-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    + .el-card {
      margin-top: 20px;
    }
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  //冲红高亮
  /deep/.tableRowClassName {
    background-color: #fce3e3;
    // color: red;
  }
  // 表头颜色
  /deep/ .tableOneClassName th {
    background: #ddeeff;
  }

  /deep/ .tableTwoClassName th {
    background: #edf3b7cc;
  }

  /deep/ .tableThreeClassName th {
    background: #cbfad9;
  }
  .leftTable {
    margin-left: 30px;
  }
  .colorTable {
    display: inline-block;
    width: 30px;
    height: 10px;
  }
  .tableOneClassName {
    background: #ddeeff;
  }
  .tableTwoClassName {
    background: #edf3b7cc;
  }
  .tableThreeClassName {
    background: #cbfad9;
  }
  /deep/ .undefined {
    border-bottom: 0px;
  }
  /deep/.el-table__cell {
    border-left: 1px solid #ebeef5 !important;
  }

  /deep/.el-table--mini {
    .el-table__cell {
      height: 32px;
    }
    .cell {
      padding: 0;
    }
  }
}
.fj-box {
  display: flex;
  justify-content: space-evenly;
  .fj-text {
    //超出一行省略号
    white-space: nowrap; //禁止换行
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; //...
  }
}
#editDaalog,
#PaymentIsShow {
  /deep/.el-dialog__body {
    padding: 0px 10px;
    .el-form-item {
      margin-bottom: 12px;
    }
  }
}
.footer-statistics {
  display: flex;
  flex-wrap: wrap;
  line-height: 44px;
  margin-top: 15px;
  .footer-statistics-total {
    color: #000;
    font-weight: 700;
  }
  .footer-statistics-ccenter {
    margin-left: 40px;
  }
}
.dialogContentHeight {
  height: 250px;
  overflow: auto;
  white-space: pre-wrap;
  padding: 0px 20px;
}
.operate {
  padding: 5px;
  color: #fff;
}
.emove_Bgc {
  background-color: #f78888;
}
.Edit_Bgc {
  background-color: #e6a23c;
}
.blush_Bgc {
  background-color: #6391f3c2;
}
.proceeds_Bgc {
  background-color: #85ce61c2;
}
.addProceeds_Bgc {
  background-color: #6391f3c2;
}
.isProceeds_Bgc {
  background-color: #409eff;
}
// input type为number，只输入数字
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}

// 还原 input 组件禁用后的置灰效果
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #f5f7fa !important;
  border-color: #e4e7ed !important;
  color: #c0c4cc !important;
  cursor: not-allowed;
}
.project-jincome-top {
  margin-bottom: 10px;
  color: #303133;
  display: flex;
  justify-content: space-between;
}
.project-jincome-fw {
  font-size: 18px;
  font-weight: 700;
}
.deadline-color-red {
  color: red;
}
.deadline-color-green {
  color: green;
}
/deep/.el-button.is-disabled.el-button--text {
  background-color: #409eff;
}
</style>
